.mobile-index-menu-container{
    width: 100%;
    height: 3rem;

    /*background-color: red;*/

    display: flex;
    flex-direction: row;

    position: fixed;
    top: 0;

    z-index: 1;    
}

.mobile-index-container-collapsed{
    width: calc(100% - 1rem);
    height: 100%;
    
    margin-right: 0.5rem;
    background: linear-gradient(90deg, #242938, #242938);

    float: left;

    /*border-bottom-left-radius: 20px;*/
    border-bottom-right-radius: 20px;

    font-family: 'Poppins';
    color: #e5dece;;
    font-size: 0.8rem;
    line-height: 1;

}

.mobile-index-container-expanded{
    width: calc(100% - 1rem);
    height: 9rem;
    
    /*margin-top: -0.19rem;*/
    margin-right: 0.5rem;
    background: linear-gradient(90deg, #242938, #242938);

    float: left;

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;


    font-family: 'Poppins';
    color: #e5dece;;
    font-size: 0.8rem;
    line-height: 1;
    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mobile-index-tile{
    width: 45%;
    height: 3rem;

/*    border: 1px solid white;
    box-sizing: border-box;*/

    float: left;
    display: flex;
    justify-items: center;
    align-items: center;
}

.mobile-index-tile-icon{
    height: 2.5rem;
    width: 10%;

    /*border: 1px solid red;
    box-sizing: border-box;*/

    float: right;

    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
}

.mobile-index-item{
    display: flex;
    align-items: center;
    margin-left: 20%;
    margin-right: auto;

    /*border: 1px solid blue;
    box-sizing: border-box;*/
}

.mobile-index-item-label{
    margin-right: 0.2rem;
}

.mobile-index-expand-container{
    height: 100%;
    width: 100%;
    /*aspect-ratio : 1 / 1;*/

    margin-right: auto;
    margin-left: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    /*border: 1px solid white;*/

    overflow: hidden;

}

.mobile-index-expand-icon{
    margin-top: 0.4rem; /* Fullösning */
    scale: 0.2;
    /*border: 1px solid white;*/
}

.mobile-menu-icon-container{
    height: 100%;
    aspect-ratio : 1 / 1;

    background: linear-gradient(90deg, #242938, #232733);

    margin-right: auto;
    margin-left: auto;

    /*border-radius: 50%;*/
    border-bottom-left-radius: 20px;
    /*border-bottom-right-radius: 20px;*/

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.mobile-menu-icon{
    scale: 0.3;
}

.upside-down{
    rotate: 180deg;
}

.mobile-menu-container{
    width: 100%;


    margin-top: 3.5rem;

    background: linear-gradient(45deg, #242938, #232733);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;


    position: absolute;

    text-align: left;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mobile-menu-item-large{
    margin-left: 1rem;
    padding-top: 0.2rem;


    font-family: 'Poppins';
    color: #e5dece;;
    font-size: 1rem;
    line-height: 1;
}

.mobile-menu-item{
    margin-left: 1rem;

    font-family: 'Poppins';
    color: #e5dece;
    font-size: 0.8rem;
    line-height: 1;
}

.white-text{
    color: #e5dece;
}

.active{
    text-decoration: underline;
}

/* Loading placeholder */
.mobile-index-loading-flag{
    width: 20px;
    height: 20px;
    border-radius: 50%;

    background-color: red;
}

.mobile-index-loading-text{
    width: 80px;
    height: 20px;
    margin-left: 5px;

    background-color: red;
}
