.footer{
    /*margin-top: 10px;*/
    /*background-color: #242938;*/
    height: 100%;
    width: 100%;
    /*min-width: 1200px;*/
    border-radius: 25px;
}

.footer-section{
    float: left;
    height: 100%;
    width: calc(100% / 3);

}

.footer-item{
    font-family: 'Poppins', sans-serif;
    color: #e3dfcf60;
    display: flex;
    font-size: 14px;
    align-items: center;
    height: 100%;
    width:min-content;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap;
}