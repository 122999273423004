.mobile-dash-aum-tile-container{
    width: 95%;
    margin-left: auto;
    margin-right: auto;

    margin-top: 1.2rem;
    background: linear-gradient(90deg, #242938, #434651);

    border-radius: 20px;
    padding-bottom: 0.5rem;
}

.mobile-dash-aum-header-small{
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 1rem;
    line-height: 1;

    padding-left: 1rem;
    padding-top: 1rem;
}

.mobile-dash-aum-header{
    font-family: 'Poppins';
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1;

    color: #bbad87;

    padding-left: 1rem;

    margin-top: -0.75rem;
}

.mobile-aum-table{
    margin-top: -1rem;
    
    width: calc(100% - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;

    font-family: 'Poppins';
    font-size: 0.8rem;
    border-collapse: collapse;


}

.mobile-aum-table-td{

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}   

.mobile-aum-table-tr{
    line-height: 0;
}

.align-right{
    text-align: right;
}