.fund-chart-print-page-overlay{
    /*visibility: hidden;*/
    position: absolute;
    z-index: -9;

    width: 100%;
    height: 90%;

    background: linear-gradient(145deg, rgba(21,28,45,1) 0%, rgba(17,22,36,1) 100%);
}

.fund-chart-print-page{   
    position: absolute;
    z-index: -10;

    background: rgba(255, 255, 255, 0); /* Set background to transparent so it does not show below content on page on smaller screens */
    width: 21cm;
    height: 29.7cm;
    /*display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);*/

    display: flex;
    align-items: center;
}

.fund-chart-print-page-content{
    width: 19.5cm;
    height: 28.2cm;
    /*border: 1px solid red;
    box-sizing: border-box;*/

    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

.fund-chart-page-content-header-container{
    width: 100%;
    height: 100px;

    margin-bottom: -40px;
    /*border: 1px solid red;
    box-sizing: border-box;*/
}


.fund-chart-page-content-header{
    font-family: 'PlayFair Display', sans-serif;
    color: #2F3342;
    font-size: 2.25rem;
    line-height: 0;
    margin-top: 14px;
}

.fund-chart-page-content-sub-header{
    font-family: 'Poppins';
    color: #2F3342;
    font-size: 1.25rem;
    line-height: 0;
}

.fund-chart-print-chart-container{
    width: 100%;
    height: 400px;

    /*border: 1px solid #0037ff;
    box-sizing: border-box;*/
}

.fund-chart-print-return-section{
    /*border: 1px solid red;*/
    display: flex;
    gap: 8px;
}

.print-chg-pos{
    color: #52A66A;
}

.print-chg-neg{
    color: #AE433E;
}