.mobile-footer-container{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.mobile-footer-section{
    width: 50%;
    float: left;
    text-align: center;
}

.mobile-footer-header{
    font-family: 'Poppins';
    font-size: 0.7rem;
    line-height: 0;
    color: rgb(87, 87, 87);
}

.mobile-footer-value{
    font-family: 'Poppins';
    font-size: 0.6rem;
    line-height: 0;
    color: rgb(84, 83, 83);
}
