.mobile-fund-table-container{
  padding-top: 1rem;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.pct-chg-pos{
    color: #8ed0a2 !important;
}

.pct-chg-neg{
  color: #ce8683 !important;
}


.mobile-fund-table{
  border-spacing: 0;
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;

  /*border: 1px solid red;
  box-sizing: border-box;*/


    
}

.mobile-fund-td{
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #abaaa9;
  line-height: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #dbdbdb;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85rem;

  max-width: 9rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

    
}

.mobile-fund-th{
  color: #bbad87;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 0.9rem;
  text-align: left;
  cursor: pointer;

  overflow-x: visible;
}

.mobile-flag-column{
  column-width: 1rem !important;
  
}

.mobile-fund-th:hover{
  color: #e4dbc2;
}

.text-align-right{
  text-align: right;
}