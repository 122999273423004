.dash-data-container{
    /*border: 1px solid red;*/
    height: calc(100% - 260px);
}

.dash-data-section{
    border: 1px solid rgba(0, 128, 0, 0);
    box-sizing: border-box;
    height: 50%;
}

.info-text-heading{
    font-family: 'Poppins', sans-serif;
    color: #e3dfcf;
    margin-left: 15px;
    font-size: 17px;
    height: 26px;
}

.info-text-heading-second-margin{
    margin-top: 7px
}

.data-table{
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    color: #dbdbdb;
    border-spacing: 0;
    border-collapse: collapse;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;

    /*border: 1px solid white;*/
}

.data-th, .data-td{
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: #abaaa9;
    line-height: 0px;

    max-width: 150px; /* Does not work without this one, but does not seem to affect */

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

tr:hover {
    /*background-color: rgb(21,28,45);*/
    font-weight: bold;
}

.pct_neg {
    color: #ce8683;
    /*font-family: Arial, Helvetica, sans-serif;*/
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
}

.pct_pos {
    color: #8ed0a2;
    /*font-family: Arial, Helvetica, sans-serif;*/
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
}

.col-flag{
    width: 22px;
}

.col-asset{
    width: calc(100% - 22px - 80px);
}

.col-return{
    width: 80px;
    text-align: right;
}