.fund-stats-grid-container{
    width: calc(100% - 20px);
    height: calc(100% - 10px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;

    /*border: 1px solid greenyellow; 
    box-sizing: border-box;*/

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.div1 { grid-area: 1 / 1 / 2 / 3; }
.div2 { grid-area: 1 / 3 / 2 / 5; }
.div3 { grid-area: 1 / 5 / 3 / 7; }
.div4 { grid-area: 2 / 1 / 3 / 3; }
.div5 { grid-area: 2 / 3 / 3 / 5; }

.fund-stats-tile{
    /*border: 1px solid white;/*rgb(21,28,45);
    box-sizing: border-box;*/
    background-color: rgb(21,28,45);
    border-radius: 20px;
    display: flex;
}

.fund-stat-tile-content{
    width: fit-content;
    height: fit-content;

    margin: auto;

    text-align: left;
}

.fund-stats-tile-header{
    color: #bbad87;
    font-family: 'Playfair Display', serif;
    font-size: 150%;
}

.fund-stats-tile-text{
    color: #dbdbdb;
    font-family: 'Poppins', sans-serif;
    font-size: 120%;
    line-height: 0;
    padding: 2%;
}

.fund-stats-tile-text-small{
    color: #dbdbdb;
    font-family: 'Poppins', sans-serif;
    font-size: 100%;
    /*line-height: 0;*/
    padding: 2%;
    }

.chg-pos{
    color: #8ed0a2 !important;
}

.chg-neg{
    color: #ce8683 !important;
}

.fund-stats-asset-allocation-chart-container{
    width: 100%;
    height: 100%;
}