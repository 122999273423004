.mobile-dash-fund-tile{
    width: 95%;
    height: 11rem;
    margin-left: auto;
    margin-right: auto;

    margin-top: -1rem;

    background: linear-gradient(90deg, #242938, #434651);
    border-radius: 20px;

    position: relative;

    /*border: 1px solid #e5dece00;*/
    box-sizing: border-box;

    z-index: 0;
}

.mobile-dash-fund-tile-title{
    font-family: 'PlayFair Display', sans-serif;
    color: #bbad87; /*#e5dece;*/
    font-size: 2rem;
    padding-top: 0.5rem;
    margin-left: 1rem;
}

.mobile-dash-fund-tile-return{
    font-family: 'Poppins';
    color: #e5dece;
    font-size: 1.4rem;

    margin-left: 1rem;
    margin-top: -1.8rem;
}

.mobile-dash-chart-container{
    width: 100%;
    height: 100%;
    z-index: -1; /*-1*/

    position: absolute;

    /*border: 1px solid white;*/
    box-sizing: border-box;

    overflow: hidden;
    border-radius: 20px;
}

.mobile-dash-info-container{
    z-index: 2;
    width: 100%;
    height: 100%;
}

.mobile-dash-largest-movers-container{
    width: 100%;
    z-index: 2;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    column-gap: 1.2rem;

    margin-top: -1rem;
}

.mobile-view-top-holdings{
    margin-left: 1rem;
}

.mobile-view-bottom-holdings{
    margin-right: 1rem;
}

.mobile-dash-table{
    width: 100%;
    max-width: 100%;

    font-family: 'Poppins';
    font-size: 0.8rem;
}

.mobile-dash-table-asset-td{
    align-items: left;
    width: 72%;
    max-width: 5px; /* Does not work without this one, but does not seem to affect */

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mobile-dash-table-return-td{
    text-align: right;
    width: 28%;
    max-width: 28%;
    max-width: 100px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mobile-dash-table-tr{
    height: 1.2rem;
}

.mobile-dash-chg-pos{
    color: #8ed0a2;
}

.mobile-dash-chg-neg{
    color: #ce8683;
}