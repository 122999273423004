@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Poppins:wght@200&display=swap');

:root {
    --blue-card: #242938;
    --white: #ffffff;
    --card-border-radius: 25px;
  }
  
.index-bar-container{
    height: 40px;
    width: 100%;
    /*position: fixed;*/
}

.footer-container{
    height: 40px;
    width: 100%;
}

.dashboard-container {
    width: 100vw;
    height: calc(100vh - 50px - 40px);
}

.dashboard-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    padding-top: 10px;
    height: calc(100% - 10px);
}

.graph-box {
    margin-top: -40px;
    margin-bottom: -10px;
    /*border: 1px solid pink;*/
}

.dash-fund-container{
    background-color: var(--blue-card);
    padding-bottom: 20px;
    border-radius: var(--card-border-radius);
}

.mobile-dash-container{
    width: 100%;
    min-height: calc(100vh - 3rem);

    padding-top: 3rem;


    background: linear-gradient(to right bottom, #191325, #1a182a, #1c1d2f, #1d2234, #1f2739);

    color: #E5DECE;
}