.fund-view-container{
    /*min-height: calc(100vh - 40px - 10px);*/
    width: 100%;
    height: calc(100vh - 50px);
    min-height: 1000px;
    padding-top: 10px;
}

.fund-view-content{
    display: grid;
    height: 100%;
    width: 100%;
    
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.fund-view-graph-container{
    grid-area: 1 / 1 / 4 / 4;
    background-color: var(--blue-card);
    border-radius: var(--card-border-radius);
    height: 100%;
    /*max-height: 40rem;*/
    /*position: relative;*/
}

.fund-view-return{
    padding-left: 6px;
}

.fund-view-table-container{
    grid-area: 1 / 4 / 6 / 6;
    background-color: var(--blue-card);
    border-radius: var(--card-border-radius);
    position: relative;
    /*border: 1px solid green;*/
}

.fund-view-info-container{
    grid-area: 4 / 1 / 6 / 4;
    background-color: var(--blue-card);
    border-radius: var(--card-border-radius);
    color:aliceblue;
}

.fund-view-header{
    color: #bbad87;
    font-family: 'Playfair Display', serif;
    height: 35px;
    font-size: 27px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
    float: left;
}

.fund-view-pct{
    color: #8ed0a2;
    font-family: 'Poppins', sans-serif;
    font-size: 27px;
    margin-top: 5px;
    margin-left: 15px;
    float: left;
}

.fund-stats-container{
    width: 100%;
    height: calc(100% - 45px);
    float: right;

    /*border: 1px solid red;
    box-sizing: border-box;*/
}

/* Mobile */

.mobile-fund-container{
    margin-top: 4rem;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(90deg, #242938, #434651);
    border-radius: 20px;
}

.mobile-table-container{
    margin-top: 1.25rem;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(90deg, #242938, #434651);
    border-radius: 20px;
    padding-bottom: 0.75rem;
}

.mobile-stats-container{
    margin-top: 1.25rem;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(90deg, #242938, #434651);
    border-radius: 20px;
    margin-bottom: 1.25rem;
}
