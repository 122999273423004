@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Poppins:wght@200&display=swap');

.index-menu-bar-container{
    height: 40px;
    width: 100%;
    max-width: inherit;

    display: flex;
    flex-direction: row;
    position: fixed;
    width:inherit;


    z-index: 999;
}

.menu-bar{
    width: calc(3rem - 10px);
    height: 100%;
    float: right;
    margin-left: 10px;
    background-color: #242938;
    border-bottom-left-radius: 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-left: 1px solid rgba(27, 29, 46, 0.657);
    border-bottom: 1px solid rgba(27, 29, 46, 0.657);
}

.menu-icon{
    height: 100%;
    aspect-ratio : 1 / 1;
    scale: 0.25;
}

.menu-container{
    height: calc(100vh - 50px);
    width: 350px;

    position: absolute;
    z-index: 999;
    margin-top: 50px;
    background: rgba(27, 29, 46, 0.657);
    box-shadow: 10 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(96, 98, 121, 0.27);

    top: 0;
    right: 0;
    
}

.menu-item-large{
    font-size: 1.5rem;
    font-family: 'Poppins';
    margin-left: 3rem;
    color: #e3dfcf;
}

.menu-item{
    font-size: 1rem;
    font-family: 'Poppins';
    margin-left: 4rem;
    color: #e3dfcf;

    line-height: 1;
}

.menu-log-out{
    font-size: 1rem;
    font-family: 'Poppins';
    margin-left: 3rem;
    color: #e3dfcf;
    margin-top: 3rem;
    cursor: pointer;
}

.index-bar{
    background-color: #242938;
    height: 40px;
    width: calc(100% - 3rem);
    /*min-width: 1200px;*/
    float: left;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;

    border-right: 1px solid rgba(27, 29, 46, 0.657);
    border-left: 1px solid rgba(27, 29, 46, 0.657);
    border-bottom: 1px solid rgba(27, 29, 46, 0.657);

    overflow: hidden;
}

.index-display{
    float: left;
    /*border: 1px solid pink;*/
    height: 100%;
    width: calc(100% / 6);
    min-width: 180px;
}


.index-item{
    font-family: 'Poppins', sans-serif;
    color: #e3dfcf;
    font-size: 17px;
    display: flex;
    align-items: center;
    height: 100%;
    width: min-content;
    margin-left: auto;
    margin-right: auto;

    /*border: 1px solid greenyellow;
    box-sizing: border-box;*/
}

.index-item:hover{
    /*background-color: rgb(21,28,45);
    padding-top: -5px;*/
    font-weight: bold;
}

.index-item-flag{
    float: left;
    display:table-cell;
    vertical-align:middle;   
    padding-right: 8px;
}

.index-item-asset{
    float: left;
    padding-right: 8px;
}

.index-item-return{
    float: left;
}

/* Index bar item loading placeholder */
.index-bar-item-loading-placeholder-flag{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
}

.index-bar-item-loading-placeholder-asset{
    width: 120px;
    height: 22px;
    background-color: red;
}

/* Shimmer Effect */
.shimmer {
    background: #242938;
    background-image: linear-gradient(to right, #2c3245 0%, #424858 50%, #2c3245 100%);
    background-repeat: no-repeat;
    background-size: 700%;/*800px 104px; */
    /*display: inline-block;
    position: relative; */
    
    animation-duration: 1s;
    animation-fill-mode: forwards; 
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    }

    @keyframes placeholderShimmer {
        0% {
          background-position: 0% 0;
        }
        
        100% {
          background-position: 100% 0; 
        }
      }