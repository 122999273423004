.fund-table-container{
    height: calc(100% - 70px);

    width: calc(100% - 20px);

    position: absolute;
    overflow: hidden;
    overflow: auto;

    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 10px;
    margin-right: 10px;
}

/* Scrollbar customisation*/
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgb(21,28,45);
  /*border: 1px solid #646874*/
  
}
   
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(21,28,45); 
  width: 5px;
  border-radius: 5px;
  border: 1px solid #dbdbdb44;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dbdbdb44;
}

.pct-chg-pos{
    color: #8ed0a2 !important;
}

.pct-chg-neg{
  color: #ce8683 !important;
}

.fund-table{
  border-spacing: 0;
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;

  /*border: 1px solid red;
  box-sizing: border-box;*/
}

.fund-td{
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #abaaa9;
  line-height: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #dbdbdb;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;


  max-width: 200px; /* Does not work without this one, but does not seem to affect */

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  }

.fund-th{
  color: #bbad87;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  cursor: pointer;
  font-weight: 400;
}

.fund-th:hover{
  color: #e4dbc2;
}

.align-right{
  text-align: right;
}