.mobile-history-tile-container{
    background: linear-gradient(90deg, #242938, #434651);
    border-radius: 20px;
    height: 100%;
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    overflow-y: hidden;
    /*width: 100%;*/

    
}

.mobile-history-tile-header{
    height: 4rem;

    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-top: -0.5rem;

    font-size: 1.5rem;
    font-family: 'PlayFair Display', sans-serif;
    color: #bbad87;
    text-align: left;
    
}

.mobile-history-tile-table-container{
    margin-top: 0.5rem;
    width: calc(100% - 2rem);
    height: calc(100% - 4rem - 1rem);
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.mobile-history-tile-table{
    width: 100%;
    font-family: 'Poppins';
    color: #dbdbdb;
    padding-bottom: 1rem;
    border-spacing: 0;

    margin-right: 0.4rem;
}

.mobile-history-tile-table-header{
    color: #e3dfcf;
}

.mobile-history-table-th{
    border-bottom: 1px solid #abaaa9;
    border-collapse: collapse;
    font-weight: normal;
}

.mobile-history-table-td{
    border-bottom: 1px solid #abaaa9;
    border-collapse: collapse;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* Expanded history tile */ 
.mobile-history-tile-expanded-container{
    width: 85%;
    height: calc(100vh - 5rem);
    
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-left: auto;

    top: calc((100vh - (100vh - 5rem))/2 + 1.4rem);
    left: 7.5%;
    z-index: 10;

    position: fixed;

    background-color: #242938d0;
    border: 1px solid rgba(66, 65, 65, 0.618);
    box-sizing: border-box;
    backdrop-filter: blur(8px);
    border-radius: 20px;

    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.305);
}

.mobile-history-tile-expanded-backdrop{
    height: 100vh;
    width: 100vw;
    top:0;
    left: 0;
    position: fixed;
    background-color: #00000074;
}

.mobile-history-tile-expand-header-container{
    height: 3rem;
    width: 100%; 

    /*border: 1px solid red;
    box-sizing: border-box;*/
}

.mobile-history-tile-expand-header{
    /*width: 8rem;*/
    padding-left: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    
    font-size: 1.5rem;
    font-family: 'PlayFair Display', sans-serif;
    color: #bbad87;
    text-align: left;

    float: left;
}

.mobile-history-tile-expand-close{
    height: 100%;
    font-family: 'Poppins';
    font-size: 0.75rem;
    color: #E5DECE;
    text-align: top;
    float: right;
    text-align: right;
    padding-right: 1rem;
    cursor: pointer;
}

.mobile-history-tile-expand-table-container{
    height: calc(100% - 3rem - 2rem);
    width: 90%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;

    overflow-x: hidden;
    overflow-y: scroll;

}

.mobile-history-tile-table-expand{
    width: 98%;
    font-family: 'Poppins';
    color: #dbdbdb;
    padding-bottom: 1rem;
    border-spacing: 0;

    margin-right: 0.4rem;
}