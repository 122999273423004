.dash-heading {
    color: #bbad87;
    text-align: center;
    font-family: 'Playfair Display', serif;
    /*font-family: 'Poppins', sans-serif;*/
    font-size: 27px;
    margin-top: 10px;
}

.dash-heading:hover {
    color: #bbad87;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 27px;
    margin-top: 10px;
    /*transition: 0.3s;*/
}

.pct-chg-pos{
    color: #8ed0a2;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    text-align: center;
    margin-top: -20px;
}

.pct-chg-neg{
    color: #ce8683;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    text-align: center;
    margin-top: -20px;
}

a {
    text-decoration: none;
}
