.mobile-fund-chart-container{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mobile-fund-chart-return-box{
    width: 100%;
    float: left;
    margin-top: -1rem;
    line-height: 1;
}

.mobile-fund-view-header{
    font-family: 'PlayFair Display', sans-serif;
    font-size: 1.5rem;
    color: #bbad87;
    margin-left: 3%;
    width: 97%;
}

.mobile-fund-chart-return-text{
    color: #dbdbdb;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
 
    padding-left: 20px;
    display: flex;
    align-items: center; /* Vertical center alignment */

}

.mobile-fund-chart-box{
    float: left;
    height: 20rem;
    width: 100%;
    

}