.mobile-stats-chart-section{
    width: 100%;
    border:1px solid rgba(255, 255, 255, 0);
    box-sizing: border-box;
}

.mobile-stats-header{
    color: #bbad87;
    font-family: 'PlayFair Display', sans-serif;
    font-size: 2rem;
    line-height: 1;

    padding-left: 0.5rem;
    padding-top: 1rem;
    margin-bottom: 0rem;
}

.mobile-stats-chart-container{
    width: 100%;
    height: 20rem;

    margin-bottom: -1rem;
}

