.soundboardContainer{
    width: 100%;

    margin-top: 1rem;
    /*border: 1px solid red;
    box-sizing: border-box;*/

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    overflow:visible;
}

.sounboardButton{
    margin: 1rem;
    width: 80%;
    height: 3rem;

    border: 1px solid #E5DECE;
    border-radius: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    

    font-family: 'Poppins';
    font-size: 1rem;
    color: #E5DECE;

    cursor: pointer;
}

.sounboardButton:hover{
    background-color: #E5DECE;
    color: #1f2739;
}
