.history-tile-container{
    background-color: #242938;
    border-radius: 1.25rem;
    height: 100%;
    /*width: 100%;*/
}

.history-tile-header{
    width: 100%;
    height: 4rem;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.5rem;
    font-family: 'PlayFair Display', sans-serif;
    color: #bbad87;
    text-align: center;
}

.history-tile-table-container{
    width: 95%;
    height: calc(100% - 4rem - 1rem);
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;

    overflow-y: scroll;
}

.history-tile-table{
    width: calc(100% - 0.4rem);
    font-family: 'Poppins';
    color: #dbdbdb;
    padding-bottom: 1rem;
    border-spacing: 0;

    margin-right: 0.4rem;
}

.history-tile-table-header{
    color: #e3dfcf;
}

.history-table-th{
    border-bottom: 1px solid #abaaa9;
    border-collapse: collapse;
    font-weight: normal;
}

.history-table-td{
    border-bottom: 1px solid #abaaa9;
    border-collapse: collapse;
    padding-top: 5px;
    padding-bottom: 5px;
}

.align-left{
    text-align: left;
}

.align-right{
    text-align: right;
}

.history-chg-neg{
    color: #ce8683;
}

.history-chg-pos{
    color: #8ed0a2;
}