.history-indexbar-container{
    width: 100%;
}

.history-container{
    width: 100%;
    height: 100vh;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.history-intro-section{
    height: 14rem;
    width: calc(100% - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;

    padding-top: 2rem;

    margin-bottom: 3rem;
    border: 1px solid rgba(0, 0, 255, 0);
    box-sizing: border-box;
}

.history-intro-section-heading{
    font-family: 'Poppins';
    font-size: 2rem;
    color: #E5DECE;
    line-height: 1;

}

.history-intro-section-text{
    width: 100%;
    max-width: 1000px;
    font-family: 'Poppins';
    font-size: 1rem;
    color: #E5DECE;
    line-height: 1.25;
}

.history-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 0px;
}

.history-grid-section{

    height: calc(100vh - 14rem - 4rem);
}


/* Mobile */
.history-mobile-container{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(145deg, rgba(21,28,45,1) 0%, rgba(17,22,36,1) 100%);
    
    /* To account for mobileIndexBarMenu*/
    margin-top: -4rem;
    padding-top: 4rem;
}

.history-mobile-intro-section{
    width: 95%;
    margin-top: 4rem;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.history-mobile-intro-section-heading{
    font-family: 'Poppins';
    font-size: 2rem;
    color: #E5DECE;
    line-height: 1;
}

.history-mobile-intro-section-text{
    font-family: 'Poppins';
    font-size: 1rem;
    color: #E5DECE;
    line-height: 1.25;

    margin-bottom: 1rem;
    margin-top: -2rem;
    border: 1px solid rgba(255, 255, 255, 0);
    box-sizing: border-box;
}


.history-mobile-intro-section-text-collapsed{
    font-family: 'Poppins';
    font-size: 1rem;
    color: #E5DECE;
    line-height: 1.25;
    margin-top: -2rem;
    margin-bottom: 1rem;
    height: 4rem;

    border: 1px solid rgba(255, 255, 255, 0);
    box-sizing: border-box;
}


.history-mobile-text-collapsed{
    /* Does not work without this one, but does not seem to affect */
    max-width: 90%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.history-mobile-expand-text-button{
    font-family: 'Poppins';
    font-size: 0.75rem;
    color: #E5DECE;
    line-height: 1;
    margin-top: -0.5rem;
}

.mobile-history-grid-container{
    width: 100%;
    height: calc(100vh - 12rem);

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 1rem;

    /*border:1px solid white;
    box-sizing: border-box;*/
}

.mobile-history-gird-section{
    /*border: 1px solid red;
    box-sizing: border-box;*/

    color: white;

    height: calc((100vh - 12rem - 2rem) / 3);

    min-height: 210px;
    max-height: 210px;
}