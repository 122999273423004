.fund-chart-container{
    height: 100%;
    width: 100%;
}

.fund-chart-return-box{
    width: 100%;
    height: 30px;
    float: left;
}

.fund-chart-return-text{
    color: #dbdbdb;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    padding-left: 20px;
    line-height: 30px;
    display: flex;
    align-items: center; /* Vertical center alignment */
    height: 30px;
}

.fund-chart-box{
    float: left;
    height: calc(100% - 30px - 35px - 10px);
    width: 100%;
}


.fund-chart-header-container-left{
    width: calc(100% - 150px);

    float: left;


}

.fund-chart-header-container-right{
    width: 120px;
    /*height: 60px;*/

    float: right;

    display: flex;
    justify-items: auto;

    align-items: center;


}

.print-icon-container{
    width: calc(40px);
    height: 100%;

    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.hide-index-container{
    width: calc(100% - 40px);
    height: 100%;

    float: left;

    color: #E5DECE;
    font-family: 'Poppins';
    text-align: right;
    font-size: 0.75rem;
    line-height: 0;
    align-items: center;

    cursor: pointer;
}

.print-icon{
    scale: 0.45;
}