@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&family=Playfair+Display&family=Poppins:wght@200;300;400&family=Roboto:wght@300;400&display=swap');

.log-in-page-container{
    width: 100vw;
    height: 100vh;
    position: fixed;

    background-image: linear-gradient(to right bottom, #191325, #1a182a, #1c1d2f, #1d2234, #1f2739);

}

@keyframes background {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

.log-in-page-gradient-container{
    width: 100%;
    height: 100vh;
    position: fixed;
    background: repeating-linear-gradient( 45deg, #24293888, #24293888 5px, #ffffff00 5px, #e5e5f700 40px );

    background-size: 400%;
    animation: background 300s infinite ease-in-out;

    z-index: -1;
}

.log-in-content-hero{
    width: calc(100% - 30rem);
    height: 100%;
    /*border: 1px solid white;*/
    box-sizing: border-box;
    float:left;
}

.hero-heading{
    font-size: 7rem;
    font-family: 'Poppins';
    color: #E5DECE;
    margin-left: 10%;

    line-height: 0;

}

.gradient-text{
    background: -webkit-linear-gradient(45deg, #E5DECE, #8ed0a2, #E5DECE, #ce8683, #E5DECE);
    background-clip: text;
    color: transparent;
    line-height: normal;
    margin-top: -5rem;
    margin-bottom: 0;

    background-size: 200%;
    animation: background 10s infinite ease-in-out;
}

.hero-sub-text{
    font-size: 4rem;
    font-family: 'Poppins';
    color: #E5DECE;
    line-height: 0;
    margin-left: 10%;

    /*background-image: linear-gradient(60deg, #E21143, #FFB03A);
    background-clip: text;
    color: transparent;*/

}

.hero-text{
    font-size: 1rem;
    font-family: 'Poppins';
    color: #E5DECE;
    line-height: 0;
    margin-left: 10%;
}


.log-in-content-card{
    width: 100%;
    width: 30rem;

    height: 100%;/*40rem;*/

    /*margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;*/

    /*display: flex;*/

    float: right;

    background-color: #24293838;
    /*border: 0.5px solid #353b4f;*/
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    /*border-left: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;*/
    /*border-radius: 20px;*/
    z-index: 1;
    overflow: hidden;
}



.log-in-page-gradient-container::before{
    --size: 1000px;
  content: '';
  position: absolute;
  left: var(--x);
  top: var(--y);
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e5dece36, transparent);
  /*background: radial-gradient(#ce8683, #8ed0a2);*/
  
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;

  border-radius: 50%;
  backdrop-filter: blur (10000.5px);
  opacity: 0.25;


}



.fn-logo-container{
    width: 8rem;

    margin-top: 6rem;
    margin-left: auto;
    margin-right: auto;
}

.log-in-form-container{
    width: 80%;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid rgba(255, 0, 0, 0);
    box-sizing: border-box;

}

.log-in-form-label{
    color: #E5DECE;
    line-height: 0;
    padding-top: 1rem;
    font-family: 'Poppins', sans-serif;
}

.log-in-form-text-input{
    width: 100%;
    height: 2rem;
    border-radius: 1rem;
    appearance: none;
    border: none;
    color: #E5DECE;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    padding-left: 1rem;
    padding-right: 1rem;

    background-color: #2F3342;
}

.log-in-form-text-input:focus{
    appearance: none;
    outline: none;
    border: 0.5px solid #e5dece96;
    background-image: linear-gradient(to right, #4f4c55, #4b4955, #464755, #404455, #394255);
}

.log-in-form-text-input:hover{
    background-image: linear-gradient(to right, #4f4c55, #4b4955, #464755, #404455, #394255);
}

.log-in-button{
    appearance: none;
    outline: none;
    border: none;
    width: 8rem;
    height: 2rem;
    border-radius: 1rem;
    color: #1F2634;
    font-family: 'Poppins', sans-serif;
    background-color: #E5DECE;
    cursor: pointer;
    margin-top: 1rem;
    margin-right: 1rem;
}

.log-in-button:hover{
    background-image: linear-gradient(to right, #e5dece, #ebe4d5, #f2ebdb, #f8f1e2, #fff8e9);
}

.text-forgotten-password{
    line-height: 0;
    font-size: 0.7rem;
    color: #82A6D9;
    font-family: 'Poppins', sans-serif;
    padding-top: 0.5rem;
    cursor: pointer;
}

.card-footer{
    height: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    position: absolute;
    margin-bottom: 1rem;
    font-size: 0.7rem;
    color: #5b5b5c;
    font-family: 'Poppins', sans-serif;
}

.log-in-form-container-mobile{
    width: 90%;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid rgba(255, 0, 0, 0);
    box-sizing: border-box;

    background-color: #24293838;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    z-index: 1;
    overflow: hidden;

    border-radius: 20px;

    padding: 1rem;
}

.fn-logo-container-mobile{
    width: 5rem;

    margin-top: 0rem;
    margin-left: auto;
    margin-right: auto;
}

.log-in-content-hero-mobile{
    margin-top: 3rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    /*border: 1px solid white;*/

    padding-bottom: 1rem;

}

.hero-heading-mobile{
    font-size: 2rem;
    font-family: 'Poppins';
    color: #E5DECE;

    line-height: 0;

}

.hero-sub-text-mobile{
    font-size: 1.5rem;
    font-family: 'Poppins';
    color: #E5DECE;
    line-height: 0;

    margin-left: 1rem;

    /*background-image: linear-gradient(60deg, #E21143, #FFB03A);
    background-clip: text;
    color: transparent;*/

}

.hero-text-mobile{
    font-size: 0.5rem;
    font-family: 'Poppins';
    color: #E5DECE;
    line-height: 0;
    margin-left: 10%;
}

.gradient-text-mobile{
    background: -webkit-linear-gradient(45deg, #E5DECE, #8ed0a2, #E5DECE, #ce8683, #E5DECE);
    background-clip: text;
    color: transparent;
    line-height: normal;

    margin-bottom: 0;
    margin-top: -1rem;

    background-size: 200%;
    animation: background 10s infinite ease-in-out;
}

.card-footer-mobile{
    height: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    margin-top: 2rem;
    margin-bottom: 0.2rem;
    font-size: 0.7rem;
    color: #5b5b5c;
    font-family: 'Poppins', sans-serif;
}

.failed-login-box{
    width: 70%;
    margin-top: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;

    background-color: rgb(186, 73, 73);
    border-radius: 20px;

    text-align: center;
    font-family: 'Poppins';
    color: #faf9f5;
    line-height: 1.2;
    
}